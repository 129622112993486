<template>
    <div>home</div>
</template>

<script>
    export default {
        name: 'MyBlogVueIndex',

        data() {
            return {

            };
        },

        mounted() {
        },

        methods: {

        },
    };
</script>

<style lang="less" scoped>
</style>